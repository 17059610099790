import Loadable from "react-loadable";
import Loading from "../../components/loading/Loading";

const FormConsentPage = Loadable({
  loader: () => import("./view/FormConsentPage"),
  loading: Loading,
});

const SuccessFormConsentPage = Loadable({
  loader: () => import("./view/SuccessFormConsentPage"),
  loading: Loading,
});

let routes = [
  {
    path: "/form/:formId",
    name: "FormConsentPage",
    component: FormConsentPage,
  },
  {
    path: "/form-success",
    name: "SuccessFormConsentPage",
    component: SuccessFormConsentPage,
  },
];

export default routes;
