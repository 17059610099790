import Loadable from 'react-loadable';
import Loading from '../../components/loading/Loading';

const DoksulFormLampungSummary = Loadable({
  loader: () => import('./view/DoksulFormLampungSummary'),
  loading: Loading
});
const DoksulFormLampungSignature = Loadable({
  loader: () => import('./view/DoksulFormLampungSignature'),
  loading: Loading,
});
const FormLampungConfirmationPage = Loadable({
  loader: () => import('./view/FormLampungConfirmationPage'),
  loading: Loading,
});

const RoutesDoksul = [
  { path: '/uw61-summary', name: "DoksulFormLampungSummary", component: DoksulFormLampungSummary},
  { path: '/uw61-signature', name: "DoksulFormLampungSignature", component: DoksulFormLampungSignature},
  { path: '/uw61-confirmation',name:'FormLampungConfirmationPage', component: FormLampungConfirmationPage}
];

export default RoutesDoksul;