import React from 'react';
import './Loading.css';

class Loading extends React.Component {
  componentDidMount() {
    document.body.classList.add('loading-active');
  }

  componentWillUnmount() {
    document.body.classList.remove('loading-active');
  }

  render() {
    return (
      <React.Fragment>
        <div className="backdrop visible backdrop-loading active" />
        <div className="loading-container visible active">
          <div className="loading">
            <div className="loading-custom" />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Loading;
