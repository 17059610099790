/**
 * @author
 * garry.alfanzo@gmail.com
 */
import { ReducerNonFace2Face } from "../pages/nonfacetoface/ReducerNonFace2Face";
import { ReducerDoksulAmandment } from "../pages/doksul-amandemen/ReducerDoksulAmandment";
import { ReducerDoksulFormLampung } from "../pages/doksul-form-lampung/ReducerDoksulFormLampung";
import { ReducerLogin } from "../pages/login/ReducerLogin";
import { ReducerForm } from "../pages/form/ReducerForm";
import { ReducerTnc } from "../pages/pruleads-agent-tnc/ReducerTnc";

export default {
  nonface2face: ReducerNonFace2Face,
  doksulAmandment: ReducerDoksulAmandment,
  doksulFormLampung: ReducerDoksulFormLampung,
  login: ReducerLogin,
  form: ReducerForm,
  tnc: ReducerTnc
};
