/**
 * @author: garry.alfanzo@gmail.com
 */

import { watcherNonFace2Face } from "../pages/nonfacetoface/SagaNonFace2Face";
import { watcherDoksulAmandment } from "../pages/doksul-amandemen/SagaDoksulAmandment";
import { watcherDoksulFormLampung } from "../pages/doksul-form-lampung/SagaDoksulFormLampung";
import { watcherLogin } from "../pages/login/SagaLogin";
import { watcherForm } from "../pages/form/SagaForm";
import { watcherTnc } from "../pages/pruleads-agent-tnc/SagaTnc";

export default [
  ...watcherNonFace2Face,
  ...watcherDoksulAmandment,
  ...watcherDoksulFormLampung,
  ...watcherLogin,
  ...watcherForm,
  ...watcherTnc
];
