import NonFace2FaceRoutes from "../pages/nonfacetoface/RoutesNonFace2Face";
import RoutesLogin from "../pages/login/RoutesLogin";
import RoutesCommon from "../pages/common/RoutesCommon";
import RoutesDoksulAmandment from "../pages/doksul-amandemen/RoutesDoksulAmandment";
import RoutesDoksulFormLampung from "../pages/doksul-form-lampung/RoutesDoksulFormLampung";
import RoutesForm from "../pages/form/RoutesForm";
import RoutesTnc from "../pages/pruleads-agent-tnc/RoutesTnc";

const InitialRoutes = {
  ReactRoute: {
    path: "/login",
  },
};

const ContainerRoutes = [
  ...NonFace2FaceRoutes,
  ...RoutesLogin,
  ...RoutesCommon,
  ...RoutesDoksulAmandment,
  ...RoutesDoksulFormLampung,
  ...RoutesForm,
  ...RoutesTnc
];

export default {
  InitialRoutes,
  ContainerRoutes,
};
