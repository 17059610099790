import Loadable from 'react-loadable';
import Loading from '../../components/loading/Loading';

const Summary = Loadable({
  loader: () => import('./view/Summary'),
  loading: Loading,
});

const Step6 = Loadable({
  loader: () => import('./view/Step6'),
  loading: Loading,
});
const Step6Bro = Loadable({
  loader: () => import('./view/Step6Bro'),
  loading: Loading,
});
const Disclaimer = Loadable({
  loader: () => import('./view/Disclaimer'),
  loading: Loading,
});

const OTP = Loadable({
  loader: () => import('./view/OTP'),
  loading: Loading,
});
const Signature = Loadable({
  loader: () => import('./view/Signature'),
  loading: Loading,
});
const Payment = Loadable({
  loader: () => import('./view/Payment'),
  loading: Loading,
});
const ConfirmationPage = Loadable({
  loader: () => import('./view/ConfirmationPage'),
  loading: Loading,
});
const EclaimSuccess = Loadable({
  loader: () => import('./view/EclaimSuccess'),
  loading: Loading,
});
const EclaimDisclaimer = Loadable({
  loader: () => import('./view/EclaimDisclaimer'),
  loading: Loading,
});

const RoutesNonFace2Face = [
  { path: '/summary', name: 'Summary', component: Summary },
  { path: '/step6', name: 'Step6', component: Step6 },
  { path: '/step6bro', name: "Step6Bro", component: Step6Bro},
  { path: '/disclaimer', name: 'Disclaimer', component: Disclaimer},
  { path: '/signature', name: 'Signature', component: Signature},
  { path: '/otp', name:'OTP', component:OTP},
  { path: '/payment', name: 'Payment', component: Payment},
  { path: '/confirmationpage',name:'ConfirmationPage', component: ConfirmationPage},
  { path: '/eclaimsuccess',name:'EclaimSuccess', component: EclaimSuccess},
  { path: '/eclaimdisclaimer',name:'EclaimDisclaimer', component: EclaimDisclaimer},
];

export default RoutesNonFace2Face;
