import Loadable from "react-loadable";
import Loading from "../../components/loading/Loading";

const TncAgreementPage = Loadable({
  loader: () => import("./view/TncAgreementPage"),
  loading: Loading,
});

const TncAgreementStatusPage = Loadable({
  loader: () => import("./view/TncAgreementStatusPage"),
  loading: Loading,
});

let routes = [
  {
    path: "/terms-and-condition/:agentNumber",
    name: "TncAgreementPage",
    component: TncAgreementPage,
  },
  {
    path: "/terms-and-condition/status/:agentNumber",
    name: "TncAgreementStatusPage",
    component: TncAgreementStatusPage,
  },
];

export default routes;
