import {
  SUMMARY,
  SUMMARY_SUCCESS,
  SUMMARY_FAILED, 
  STEP_6,
  STEP_6_SUCCESS,
  STEP_6_FAILED,
  DISCLAIMER,
  DISCLAIMER_SUCCESS,
  DISCLAIMER_FAILED,
  CONFIRMATION_PAGE,
  CONFIRMATION_PAGE_SUCCESS,
  CONFIRMATION_PAGE_FAILED,
  CLOSE_DIALOG
} from './ConfigNonFace2Face';

const initialState = {
  dialogOpen: false,
  dialogContentText: '',
  step6: {},
  confirmationPage: {}
};

export function ReducerNonFace2Face(state = initialState, action) {
  let newState = {...state};
   //console.log(("==ReducerNonFace2Face newState: ", newState);
   //console.log(("==ReducerNonFace2Face action: ", action);
   switch (action.type) {

    case SUMMARY:
      // //console.log(("[ReducerLogin]ONCLICK_LOGIN: ", action.value);
      // //console.log(("[ReducerLogin]ONCLICK_LOGIN username: ", action.value.username);
      // //console.log(("[ReducerLogin]ONCLICK_LOGIN password: ", action.value.password);
      return { ...state, viewData: null };


    case SUMMARY_SUCCESS:
     //console.log(("[ReducerNonFace2Face] submitSuccess: ", action);
     //console.log(("[ReducerNonFace2Face] action status: ", action.value.status);
     //console.log(("[ReducerNonFace2Face] action data: ", action.value.data);

      const summary ={
        redirect:true,
        data:action.value.data,
        responseCode:action.value.status
      }
    

      return {
        ...state,
        summary
      };

    case SUMMARY_FAILED:
    console.log("[ReducerSummary] SUMMARY_ERROR: ", action);
    // //console.log(("[ReducerLogin] action status: ", action.value.response.status);
    // //console.log(("[ReducerLogin] action data: ", action.value.response.data);
      return {
        dialogOpen: true,
        redirect:true,
        respond: action.value
      };
  
    case STEP_6:
      // //console.log(("[ReducerLogin]ONCLICK_LOGIN: ", action.value);
      // //console.log(("[ReducerLogin]ONCLICK_LOGIN username: ", action.value.username);
      // //console.log(("[ReducerLogin]ONCLICK_LOGIN password: ", action.value.password);
      return { ...state, viewData: null };


    case STEP_6_SUCCESS:
     //console.log(("[ReducerNonFace2Face] submitSuccess: ", action);
     //console.log(("[ReducerNonFace2Face] action status: ", action.value.status);
     //console.log(("[ReducerNonFace2Face] action data: ", action.value.data);

      const step6 ={
        redirect:true,
        isSuccess:action.value.data.success
      }
    

      return {
        ...state,
        step6
      };

    case STEP_6_FAILED:
    // //console.log(("[ReducerLogin] LOGIN_ERROR: ", action);
    // //console.log(("[ReducerLogin] action status: ", action.value.response.status);
    // //console.log(("[ReducerLogin] action data: ", action.value.response.data);
  
      return {
        dialogOpen: true,
        dialogContentText: action.value.response.data.errorMessage,
        redirect:true,
        isSuccess: false
      };

      case DISCLAIMER:
      // //console.log(("[ReducerLogin]ONCLICK_LOGIN: ", action.value);
      // //console.log(("[ReducerLogin]ONCLICK_LOGIN username: ", action.value.username);
      // //console.log(("[ReducerLogin]ONCLICK_LOGIN password: ", action.value.password);
      return { ...state, viewData: null };


    case DISCLAIMER_SUCCESS:
     //console.log(("[ReducerNonFace2Face] submitSuccess: ", action);
     //console.log(("[ReducerNonFace2Face] action status: ", action.value.status);
     //console.log(("[ReducerNonFace2Face] action data: ", action.value.data);

      const disclaimer ={
        redirect:true,
        isSuccess:action.value.data.success
      }
    

      return {
        ...state,
        disclaimer
      };

    case DISCLAIMER_FAILED:
    // //console.log(("[ReducerLogin] LOGIN_ERROR: ", action);
    // //console.log(("[ReducerLogin] action status: ", action.value.response.status);
    // //console.log(("[ReducerLogin] action data: ", action.value.response.data);
    
      return {
        dialogOpen: true,
        dialogContentText: action.value.response.data.errorMessage,
        redirect:true,
        isSuccess: false
      };

      case CONFIRMATION_PAGE:
      // //console.log(("[ReducerLogin]ONCLICK_LOGIN: ", action.value);
      // //console.log(("[ReducerLogin]ONCLICK_LOGIN username: ", action.value.username);
      // //console.log(("[ReducerLogin]ONCLICK_LOGIN password: ", action.value.password);
      return { ...state, viewData: null };


    case CONFIRMATION_PAGE_SUCCESS:
     //console.log(("[ReducerNonFace2Face] submitSuccess: ", action);
     //console.log(("[ReducerNonFace2Face] action status: ", action.value.status);
     //console.log(("[ReducerNonFace2Face] action data: ", action.value.data);

    let confirmationPage = {
        redirect:true,
        data:action.value.data,
        isSuccess:action.value.data.success
    }

      return {
        ...state,
        confirmationPage
      };

    case CONFIRMATION_PAGE_FAILED:
    // //console.log(("[ReducerLogin] LOGIN_ERROR: ", action);
    // //console.log(("[ReducerLogin] action status: ", action.value.response.status);
    // //console.log(("[ReducerLogin] action data: ", action.value.response.data);
   
      return {
        dialogOpen: true,
        confirmationPage: {redirect:true,
          data:action.value.data,
          isSuccess:false}
      };

    case  CLOSE_DIALOG:
      newState.dialogOpen = false;
      newState.dialogContentText = '';
      return{
        dialogOpen: newState.dialogOpen,
        dialogContentText: newState.dialogContentText,
      }

    default:
      return state;
  }
}