import {
  URL_CHECK,
  URL_CHECK_FAILED,
  URL_CHECK_SUCCESS,
  GET_CONSENT_CONTENT,
  GET_CONSENT_CONTENT_FAILED,
  GET_CONSENT_CONTENT_SUCCESS,
  ACCEPT_CONSENT,
  ACCEPT_CONSENT_FAILED,
  ACCEPT_CONSENT_SUCCESS,
  SET_INITIAL_STATUS,
} from "./ActionLogin";

const initialState = {
  urlCheck: { reqStatus: "initial", data: null },
  getConsentContent: { reqStatus: "initial", data: null },
  acceptConsent: { reqStatus: "initial", data: null },
};

export function ReducerLogin(state = initialState, action) {
  switch (action.type) {
    case URL_CHECK:
      return {
        ...state,
        urlCheck: {
          ...state.urlCheck,
          reqStatus: action.status,
        },
      };
    case URL_CHECK_FAILED:
      return {
        ...state,
        urlCheck: {
          data: null,
          reqStatus: action.status,
        },
      };
    case URL_CHECK_SUCCESS:
      return {
        ...state,
        urlCheck: {
          data: action.payload,
          reqStatus: action.status,
        },
      };
    case GET_CONSENT_CONTENT:
      return {
        ...state,
        getConsentContent: {
          ...state.getConsentContent,
          reqStatus: action.status,
        },
      };
    case GET_CONSENT_CONTENT_FAILED:
      return {
        ...state,
        getConsentContent: {
          data: null,
          reqStatus: action.status,
        },
      };
    case GET_CONSENT_CONTENT_SUCCESS:
      return {
        ...state,
        getConsentContent: {
          data: action.payload,
          reqStatus: action.status,
        },
      };
    case ACCEPT_CONSENT:
    case ACCEPT_CONSENT_FAILED:
      return {
        ...state,
        acceptConsent: {
          data: null,
          reqStatus: action.status,
        },
      };
    case ACCEPT_CONSENT_SUCCESS:
      return {
        ...state,
        acceptConsent: {
          data: action.payload,
          reqStatus: action.status,
        },
      };
    case SET_INITIAL_STATUS:
      return {
        urlCheck: {
          ...state.urlCheck,
          reqStatus: "initial",
        },
        getConsentContent: {
          ...state.getConsentContent,
          reqStatus: "initial",
        },
        acceptConsent: {
          ...state.acceptConsent,
          reqStatus: "initial",
        },
      };
    default:
      return state;
  }
}
