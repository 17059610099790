import Loadable from 'react-loadable';
import Loading from '../../components/loading/Loading';

const DoksulAmandmentSummary = Loadable({
  loader: () => import('./view/DoksulAmandmentSummary'),
  loading: Loading
});
const DoksulAmandmentSignature = Loadable({
  loader: () => import('./view/DoksulAmandmentSignature'),
  loading: Loading,
});
const AmandmentConfirmationPage = Loadable({
  loader: () => import('./view/AmandmentConfirmationPage'),
  loading: Loading,
});

const RoutesDoksul = [
  { path: '/amandment-summary', name: "DoksulAmandmentSummary", component: DoksulAmandmentSummary},
  { path: '/amandment-signature', name: "DoksulAmandmentSignature", component: DoksulAmandmentSignature},
  { path: '/amandment-confirmation',name:'AmandmentConfirmationPage', component: AmandmentConfirmationPage}
];

export default RoutesDoksul;