import { 
  DOKSUL_AMANDMENT_CONFIRMATION_PAGE,
  DOKSUL_AMANDMENT_CONFIRMATION_PAGE_SUCCESS,
  DOKSUL_AMANDMENT_CONFIRMATION_PAGE_FAILED
} from "./ConfigDoksulAmandment";

const initialState = {
  dialogOpen: false,
  dialogContentText: '',
  confirmationPage: {} 
}

export function ReducerDoksulAmandment(state = initialState, action) {
  let newState = { ...state }

  switch(action.type) {
    case DOKSUL_AMANDMENT_CONFIRMATION_PAGE:
      return { ...state, viewData: null }
    case DOKSUL_AMANDMENT_CONFIRMATION_PAGE_SUCCESS:
      let confirmationPage = {
        redirect: true,
        data: action.value.data,
        isSuccess: action.value.data.success
      }
      return { ...state, confirmationPage }
    case DOKSUL_AMANDMENT_CONFIRMATION_PAGE_FAILED:
      return {
        dialogOpen: true,
        confirmationPage: {
          redirect: true,
          data: action.value.data,
          isSuccess: false
        }
      }
    default:
      return state;
  }
}