export const DEV = 'http://localhost:3015/service-nonface2face'
export const SIT = 'http://10.170.49.104';
// export const PROD = 'https://pruforce-uat.prudential.co.id/service-nonface2face';
export const PROD = window.getConfig(4);
export const PROXY = '/proxy';
export const API_LOGIN = '/api/login';
export const API_VALIDATE = '/api/validate';
export const SERVICE_NAME = '/service-pruleads-nonface2face';
export const SERVICE_ROUND_ROBIN = '/leads-roundrobin-group';

export const SERVICE_DOMAIN_NAME = 'http://prudential.co.id/';

export const PAYMENT_CHECK_URL = window.getConfig(2);


export const xxx = window.getConfig(0);
export const yyy = window.getConfig(1);
export const zzz = window.getConfig(2);
//export const PROD = 'https://pruforce.prudential.co.id';
//need to switch by npm start
//var env = process.env.NODE_ENV || 'prod'
// console.log(process.env.NODE_ENV);
//console.log("using " +(env === 'development'? DEV : PROD) +" as path");
export const PATH = PROD;
export const SITEKEY = (process.env.NODE_ENV === 'development') ? "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI":"6LcUGfAUAAAAABkvjjKJO2TDYXSjtCLAUp5juekb";
