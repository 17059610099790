//export const STEP_6_UPLOAD_PATH= 'service-nonface2face/step6/upload';
export const SUMMARY_GET_PATH = '/getSummaries';
export const SUBMISSION_PATH = '/submission';
export const GENERATE_OTP = '/generateOTP';
export const VALIDATE_OTP = '/validateOTP';
export const UPDATE_PAYMENT = '/updatePaymentData';

//SUMMARY
export const SUMMARY = 'SUMMARY';
export const SUMMARY_SUCCESS = 'SUMMARY_SUCCESS';
export const SUMMARY_FAILED = 'SUMMARY_FAILED';

//step 6
export const STEP_6 = 'STEP_6';
export const STEP_6_SUCCESS = 'STEP_6_SUCCESS';
export const STEP_6_FAILED = 'STEP_6_FAILED';

//disclaimer

export const DISCLAIMER = 'DISCLAIMER';
export const DISCLAIMER_SUCCESS = 'DISCLAIMER_SUCCESS';
export const DISCLAIMER_FAILED = 'DISCLAIMER_FAILED';

//confirmation
export const CONFIRMATION_PAGE = 'CONFIRMATION_PAGE';
export const CONFIRMATION_PAGE_SUCCESS = 'CONFIRMATION_PAGE_SUCCESS';
export const CONFIRMATION_PAGE_FAILED = 'CONFIRMATION_PAGE_FAILED';

//signature
export const EXPIRED_OTP_PATH = '/setExpired';

export const CLOSE_DIALOG = 'CLOSE_DIALOG';