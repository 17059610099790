import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import Index from './bootstrap/ReactContainer';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import './config/i18n';
import 'capacitor-camera-preview';


ReactDOM.render(<Index />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

defineCustomElements(window);

serviceWorker.unregister();
