import { call, put, takeLatest } from 'redux-saga/effects';
import { HTTP_SERVICE } from '../../service/HttpService';
import { 
    SUMMARY,SUMMARY_GET_PATH, CONFIRMATION_PAGE,SUBMISSION_PATH
} from './ConfigNonFace2Face';
import { summarySuccess,summaryFailed , confirmationPageSuccess,confirmationPageFailed} from './ActionNonFace2Face';

    // function* workSagaSummaries(params) {


    //     console.log("[SagaSummaries]workSagaSummaries work: ",params);
    //     // //console.log(("[SagaLogin]params: ", params);
        
    //     let body = params.value;
    
    //        //console.log(("bodyToSubmit");
    //        //console.log((body);
    //     try {
    //         //const response = yield call(HTTP_SERVICE.post, API_LOGIN, body);
    //         // const responseLogin = yield call(HTTP_SERVICE.post,FPA_AUTH_PATH,loginBody);
    //         // if (responseLogin.status === 200 && responseLogin.data) {
    
    //            // localStorage.setItem('token',responseLogin.data.token);
                
    //                 const response = yield call(HTTP_SERVICE.post,SUMMARY_GET_PATH,body)
                        
    //                     if (response.status === 200 && response.data) {
    //                         yield put(summarySuccess(response));
    //                         console.log("[SagaSummaries]response (OK): ", response)
                
    //                      } 
    //                      else {
    //                          yield put(summaryFailed(response));
    //                         console.log("[SagaSummaries]error (X): ", response);
    //                      }
    //                // }
    //             //} else{
    //         //     //shud check whether this is right or not
    //         //     yield put(summaryFailed(responseLogin));
    //         // }    
    //     } catch (error) {
    //         console.log(("[SagaSummaries]error catch: ", error))
    //         yield put(summaryFailed(error))
    //     }
    // }

    function* workSagaSubmission(params) {


        console.log("[SagaNonFace2Face]workSagaSubmission work: ",params);
        // //console.log(("[SagaLogin]params: ", params);
        
        let body = params;
    
        //    console.log("bodyToSubmit");
        //    console.log(body);
        try {
           
                    const response = yield call(HTTP_SERVICE.post,SUBMISSION_PATH,body)
                        
                        if (response.status === 200 && response.data) {
                            yield put(confirmationPageSuccess(response));
                            console.log("[SagaNonFace2Face]response (OK): ", response)
                
                         } 
                         else {
                             yield put(confirmationPageFailed(response));
                            console.log("[SagaNonFace2Face]error (X): ", response);
                         }

        } catch (error) {
            console.log(("[SagaNonFace2Face]error catch: ", error))
            yield put(confirmationPageFailed(error))
        }
    }




export const watcherNonFace2Face = [
    // takeLatest(SUMMARY,workSagaSummaries),
    takeLatest(CONFIRMATION_PAGE, workSagaSubmission)
];