import Loadable from "react-loadable";
import Loading from "../../components/loading/Loading";

const NotFound = Loadable({
  loader: () => import("./view/NotFound"),
  loading: Loading,
});

const NotAccessible = Loadable({
  loader: () => import("./view/NotAccessible"),
  loading: Loading,
});

const RoutesCommon = [
  { path: "/notfound", name: "NotFound", component: NotFound },
  { path: "/not-accessible", name: "NotAccessible", component: NotAccessible },
];

export default RoutesCommon;
