const ACTION_STATUS = {
  PENDING: "pending",
  SUCCESS: "success",
  FAILED: "failed",
};

export const GET_FORM_DETAIL = "form/GET_FORM_DETAIL";
export const GET_FORM_DETAIL_SUCCESS = "form/GET_FORM_DETAIL_SUCCESS";
export const GET_FORM_DETAIL_FAILED = "form/GET_FORM_DETAIL_FAILED";

export const GET_AGENT_DETAIL = "form/GET_AGENT_DETAIL";
export const GET_AGENT_DETAIL_SUCCESS = "form/GET_AGENT_DETAIL_SUCCESS";
export const GET_AGENT_DETAIL_FAILED = "form/GET_AGENT_DETAIL_FAILED";

export const GET_CLIENT_DETAIL = "form/GET_CLIENT_DETAIL";
export const GET_CLIENT_DETAIL_SUCCESS = "form/GET_CLIENT_DETAIL_SUCCESS";
export const GET_CLIENT_DETAIL_FAILED = "form/GET_CLIENT_DETAIL_FAILED";

export const GET_LEADS_CITY = "form/GET_LEADS_CITY";
export const GET_LEADS_CITY_SUCCESS = "form/GET_LEADS_CITY_SUCCESS";
export const GET_LEADS_CITY_FAILED = "form/GET_LEADS_CITY_FAILED";

export const SUBMIT_LEAD_FORM = "form/SUBMIT_LEAD_FORM";
export const SUBMIT_LEAD_FORM_SUCCESS = "form/SUBMIT_LEAD_FORM_SUCCESS";
export const SUBMIT_LEAD_FORM_FAILED = "form/SUBMIT_LEAD_FORM_FAILED";

export const SET_INITIAL_STATUS = "form/SET_INITIAL_STATUS";

export const getFormDetail = (data, onFailed) => ({
  type: GET_FORM_DETAIL,
  payload: data,
  status: ACTION_STATUS.PENDING,
  onFailed,
});
export const getFormDetailSuccess = (data) => ({
  type: GET_FORM_DETAIL_SUCCESS,
  payload: data,
  status: ACTION_STATUS.SUCCESS,
});
export const getFormDetailFailed = (data) => ({
  type: GET_FORM_DETAIL_FAILED,
  payload: data,
  status: ACTION_STATUS.FAILED,
});

export const getAgentDetail = (data) => ({
  type: GET_AGENT_DETAIL,
  payload: data,
  status: ACTION_STATUS.PENDING,
});
export const getAgentDetailSuccess = (data) => ({
  type: GET_AGENT_DETAIL_SUCCESS,
  payload: data,
  status: ACTION_STATUS.SUCCESS,
});
export const getAgentDetailFailed = (data) => ({
  type: GET_AGENT_DETAIL_FAILED,
  payload: data,
  status: ACTION_STATUS.FAILED,
});

export const getClientDetail = (data) => ({
  type: GET_CLIENT_DETAIL,
  payload: data,
  status: ACTION_STATUS.PENDING,
});
export const getClientDetailSuccess = (data) => ({
  type: GET_CLIENT_DETAIL_SUCCESS,
  payload: data,
  status: ACTION_STATUS.SUCCESS,
});
export const getClientDetailFailed = (data) => ({
  type: GET_CLIENT_DETAIL_FAILED,
  payload: data,
  status: ACTION_STATUS.FAILED,
});

export const getLeadsCity = (data) => ({
  type: GET_LEADS_CITY,
  payload: data,
  status: ACTION_STATUS.PENDING,
});
export const getLeadsCitySuccess = (data) => ({
  type: GET_LEADS_CITY_SUCCESS,
  payload: data,
  status: ACTION_STATUS.SUCCESS,
});
export const getLeadsCityFailed = (data) => ({
  type: GET_LEADS_CITY_FAILED,
  payload: data,
  status: ACTION_STATUS.FAILED,
});

export const submitLeadForm = (data, onSuccess) => ({
  type: SUBMIT_LEAD_FORM,
  payload: data,
  status: ACTION_STATUS.PENDING,
  onSuccess,
});
export const submitLeadFormSuccess = (data) => ({
  type: SUBMIT_LEAD_FORM_SUCCESS,
  payload: data,
  status: ACTION_STATUS.SUCCESS,
});
export const submitLeadFormFailed = (data) => ({
  type: SUBMIT_LEAD_FORM_FAILED,
  payload: data,
  status: ACTION_STATUS.FAILED,
});

export const setInitialStatus = () => ({
  type: SET_INITIAL_STATUS,
});
