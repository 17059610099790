import Loadable from 'react-loadable';
import Loading from '../../components/loading/Loading';


const LoginPage = Loadable({
  loader: () => import('./view/LoginPage'),
  loading: Loading,
});

const LoginExpired = Loadable({
  loader: () => import('./view/LoginExpired'),
  loading: Loading,
});

const AcceptConsentPage = Loadable({
  loader: () => import('./view/AcceptConsentPage'),
  loading: Loading,
});

let RoutesLogin = [];

const checkUrl = function(){
    
    RoutesLogin = [];
    RoutesLogin.push({ path: '/red', name: 'LoginPage', component: LoginPage });
    RoutesLogin.push({ path: '/login', name: 'LoginPage', component: LoginPage });
    RoutesLogin.push({ path: '/expired', name: 'LoginExpired', component: LoginExpired });
    RoutesLogin.push({ path: '/accept-consent', name: 'AcceptConsentPage', component: AcceptConsentPage });
}

checkUrl();

export default RoutesLogin;
