const ACTION_STATUS = {
  PENDING: "pending",
  SUCCESS: "success",
  FAILED: "failed",
};

export const GET_AGENT_PRIORITY_TNC = "tnc/GET_AGENT_PRIORITY_TNC";
export const GET_AGENT_PRIORITY_TNC_SUCCESS = "tnc/GET_AGENT_PRIORITY_TNC_SUCCESS";
export const GET_AGENT_PRIORITY_TNC_FAILED = "tnc/GET_AGENT_PRIORITY_TNC_FAILED";

export const SET_AGENT_PRIORITY_TNC = "tnc/SET_AGENT_PRIORITY_TNC";
export const SET_AGENT_PRIORITY_TNC_SUCCESS = "tnc/SET_AGENT_PRIORITY_TNC_SUCCESS";
export const SET_AGENT_PRIORITY_TNC_FAILED = "tnc/SET_AGENT_PRIORITY_TNC_FAILED";

export const SET_INITIAL_STATUS = "tnc/SET_INITIAL_STATUS";

export const getAgentPriorityTnc = (data) => ({
  type: GET_AGENT_PRIORITY_TNC,
  payload: data,
  status: ACTION_STATUS.PENDING,
});

export const getAgentPriorityTncSuccess = (data) => ({
  type: GET_AGENT_PRIORITY_TNC_SUCCESS,
  payload: data,
  status: ACTION_STATUS.SUCCESS,
});
export const getAgentPriorityTncFailed = (data) => ({
  type: GET_AGENT_PRIORITY_TNC_FAILED,
  payload: data,
  status: ACTION_STATUS.FAILED,
});

export const setAgentPriorityTnc = (data, onSuccess, onFailed) => ({
  type: SET_AGENT_PRIORITY_TNC,
  payload: data,
  status: ACTION_STATUS.PENDING,
  onSuccess,
  onFailed
});
export const setAgentPriorityTncSuccess = (data) => ({
  type: SET_AGENT_PRIORITY_TNC_SUCCESS,
  payload: data,
  status: ACTION_STATUS.SUCCESS,
});
export const setAgentPriorityTncFailed = (data) => ({
  type: SET_AGENT_PRIORITY_TNC_FAILED,
  payload: data,
  status: ACTION_STATUS.FAILED,
});

export const setInitialStatus = () => ({
  type: SET_INITIAL_STATUS,
});
