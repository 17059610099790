import { call, put, takeLatest } from "redux-saga/effects";
import { HTTP_SERVICE } from "../../service/HttpService";
import { DOKSUL_FORM_LAMPUNG_CONFIRMATION_PAGE, DOKSUL_FORM_LAMPUNG_SUBMISSION_PATH } from "./ConfigDoksulFormLampung";
import { formLampungConfirmationPageSuccess, formLampungConfirmationPageFailed } from "./ActionDoksulFormLampung";

function* workSagaSubmission(params) {
  let body = params;
  try {
    const response = yield call(HTTP_SERVICE.post, DOKSUL_FORM_LAMPUNG_SUBMISSION_PATH, body);
    if (response.status === 200 && response.data) {
      yield put(formLampungConfirmationPageSuccess(response));
    } else {
      yield put(formLampungConfirmationPageFailed(response));
    }
  } catch (error) {
    yield put(formLampungConfirmationPageFailed(error));
  }
}

export const watcherDoksulFormLampung = [
  takeLatest(DOKSUL_FORM_LAMPUNG_CONFIRMATION_PAGE, workSagaSubmission)
]