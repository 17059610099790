import { call, put, takeLatest } from "redux-saga/effects";

import { HTTP_SERVICE } from "../../service/HTTPServiceNew";
import {
  getAgentDetailFailed,
  getAgentDetailSuccess,
  getClientDetailFailed,
  getClientDetailSuccess,
  getFormDetailFailed,
  getFormDetailSuccess,
  getLeadsCityFailed,
  getLeadsCitySuccess,
  GET_AGENT_DETAIL,
  GET_CLIENT_DETAIL,
  GET_FORM_DETAIL,
  GET_LEADS_CITY,
  submitLeadFormFailed,
  submitLeadFormSuccess,
  SUBMIT_LEAD_FORM,
} from "./ActionForm";
import { AGENT_DETAIL, CLIENT_DETAIL, LEADS_FORM, REGISTERED_CITIES, SUBMIT_LEADS_FORM } from "./ConfigForm";

const { postBase, postWithRoundRobin } = HTTP_SERVICE();

function* workerGetFormDetail({ payload, onFailed }) {
  try {
    const response = yield call(postBase, LEADS_FORM, payload);
    if (response.data.success === true && !Array.isArray(response.data.data)) {
      yield put(getFormDetailSuccess(response.data.data));
    } else {
      yield put(getFormDetailFailed(response));
      yield call(onFailed)
    }
  } catch (error) {
    yield put(getFormDetailFailed(error));
    yield call(onFailed)
  }
}

function* workerGetClientDetail({ payload }) {
  try {
    const response = yield call(postBase, CLIENT_DETAIL, payload);
    if (response.data.success === true && Array.isArray(response.data.data) && response.data.data.length >= 1) {
      yield put(getClientDetailSuccess(response.data.data[0]));
    } else {
      yield put(getClientDetailFailed(response));
    }
  } catch (error) {
    yield put(getClientDetailFailed(error));
  }
}

function* workerGetAgentDetail({ payload }) {
  try {
    const response = yield call(postBase, AGENT_DETAIL, payload);
    if (response.data.success === true && Array.isArray(response.data.data) && response.data.data.length >= 1) {
      yield put(getAgentDetailSuccess(response.data.data[0]));
    } else {
      yield put(getAgentDetailFailed(response));
    }
  } catch (error) {
    yield put(getAgentDetailFailed(error));
  }
}

function* workerGetLeadsCity({ payload }) {
  try {
    const response = yield call(postWithRoundRobin, REGISTERED_CITIES, payload);
    if (response.data.status.message === "success") {
      yield put(getLeadsCitySuccess(response.data.body));
    } else {
      yield put(getLeadsCityFailed(response));
    }
  } catch (error) {
    yield put(getLeadsCityFailed(error));
  }
}

function* workerSubmitLeadForm({ payload, onSuccess }) {
  try {
    const response = yield call(postBase, SUBMIT_LEADS_FORM, payload);
    if (response.data.success === true) {
      yield put(submitLeadFormSuccess(response.data.data));
      yield call(onSuccess, response.data.data)
    } else {
      yield put(submitLeadFormFailed(response));
    }
  } catch (error) {
    yield put(submitLeadFormFailed(error));
  }
}

export const watcherForm = [
  takeLatest(GET_FORM_DETAIL, workerGetFormDetail),
  takeLatest(GET_AGENT_DETAIL, workerGetAgentDetail),
  takeLatest(GET_LEADS_CITY, workerGetLeadsCity),
  takeLatest(SUBMIT_LEAD_FORM, workerSubmitLeadForm),
  takeLatest(GET_CLIENT_DETAIL,workerGetClientDetail)
];
