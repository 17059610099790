import { call, put, takeLatest } from "redux-saga/effects";
import _ from "lodash";

import { HTTP_SERVICE } from "../../service/HTTPServiceNew";
import {
  GET_CONSENT_CONTENT,
  URL_CHECK,
  ACCEPT_CONSENT,
  urlCheck,
  urlCheckFailed,
  urlCheckSuccess,
  getConsentContent,
  getConsentContentSuccess,
  getConsentContentFailed,
  acceptConsentFailed,
  acceptConsentSuccess,
} from "./ActionLogin";
import { CHECK_URL } from "./ConfigLogin";

const { checkUrl, consentContentAsync, acceptConsentAsync } = HTTP_SERVICE();

function* workSagaUrlCheck({ payload, callback }) {
  try {
    const response = yield call(checkUrl, CHECK_URL, payload);
    if (response.data.data.status === "true") {
      yield put(urlCheckSuccess(response.data.data));
      yield put(
        getConsentContent({
          contentId: response.data.data.contentId,
          agentNumber: response.data.data.agentCode,
          prospectId: response.data.data.prospectId,
        })
      );
      yield call(callback.onSuccess, response.data.data)
    } else {
      yield call(callback.onError)
      yield put(urlCheckFailed(response));
    }
  } catch (error) {
    yield call(callback.onError)
    yield put(urlCheckFailed(error));
  }
}

function* workSagaGetConsentContent({ payload }) {
  try {
    const response = yield call(consentContentAsync, payload);
    if (response.data.status.shortCode === "SUCCESS") {
      yield put(getConsentContentSuccess(response.data.body));
    } else {
      yield put(getConsentContentFailed(response));
    }
  } catch (error) {
    yield put(getConsentContentFailed(error));
  }
}

function* workSagaAcceptConsent({ payload }) {
  try {
    const response = yield call(acceptConsentAsync, payload);
    const uid = localStorage.getItem("uid");
    if (response.data.success) {
      yield put(acceptConsentSuccess(response.data));
      yield put(urlCheck({uid}))
    } else {
      yield put(acceptConsentFailed(response));
    }
  } catch (error) {
    yield put(acceptConsentFailed(error));
  }
}

export const watcherLogin = [
  takeLatest(URL_CHECK, workSagaUrlCheck),
  takeLatest(GET_CONSENT_CONTENT, workSagaGetConsentContent),
  takeLatest(ACCEPT_CONSENT, workSagaAcceptConsent),
];
