import { call, put, takeLatest } from "redux-saga/effects";
import { HTTP_SERVICE } from "../../service/HttpService";
import { DOKSUL_AMANDMENT_CONFIRMATION_PAGE, DOKSUL_AMANDMENT_SUBMISSION_PATH } from "./ConfigDoksulAmandment";
import { amandmentConfirmationPageSuccess, amandmentConfirmationPageFailed } from "./ActionDoksulAmandment";

function* workSagaSubmission(params) {
  let body = params;
  try {
    const response = yield call(HTTP_SERVICE.post, DOKSUL_AMANDMENT_SUBMISSION_PATH, body);
    if (response.status === 200 && response.data) {
      yield put(amandmentConfirmationPageSuccess(response));
    } else {
      yield put(amandmentConfirmationPageFailed(response));
    }
  } catch (error) {
    yield put(amandmentConfirmationPageFailed(error));
  }
}

export const watcherDoksulAmandment = [
  takeLatest(DOKSUL_AMANDMENT_CONFIRMATION_PAGE, workSagaSubmission)
]