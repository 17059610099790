import {
  GET_AGENT_DETAIL,
  GET_AGENT_DETAIL_FAILED,
  GET_AGENT_DETAIL_SUCCESS,
  GET_CLIENT_DETAIL,
  GET_CLIENT_DETAIL_FAILED,
  GET_CLIENT_DETAIL_SUCCESS,
  GET_FORM_DETAIL,
  GET_FORM_DETAIL_FAILED,
  GET_FORM_DETAIL_SUCCESS,
  GET_LEADS_CITY,
  GET_LEADS_CITY_FAILED,
  GET_LEADS_CITY_SUCCESS,
  SET_INITIAL_STATUS,
  SUBMIT_LEAD_FORM,
  SUBMIT_LEAD_FORM_FAILED,
  SUBMIT_LEAD_FORM_SUCCESS,
} from "./ActionForm";
import _ from "lodash";

const initialState = {
  formDetail: { reqStatus: "initial", data: null },
  agentDetail: { reqStatus: "initial", data: null },
  clientDetail: { reqStatus: "initial", data: null },
  leadsCity: { reqStatus: "initial", data: [] },
  submitLeadForm: { reqStatus: "initial", data: null },
};

export function ReducerForm(state = initialState, action) {
  switch (action.type) {
    case GET_FORM_DETAIL:
      return {
        ...state,
        formDetail: {
          ...state.formDetail,
          reqStatus: action.status,
        },
      };
    case GET_FORM_DETAIL_FAILED:
      return {
        ...state,
        formDetail: {
          data: null,
          reqStatus: action.status,
        },
      };
    case GET_FORM_DETAIL_SUCCESS:
      return {
        ...state,
        formDetail: {
          data: action.payload,
          reqStatus: action.status,
        },
      };
    case GET_AGENT_DETAIL:
      return {
        ...state,
        agentDetail: {
          ...state.agentDetail,
          reqStatus: action.status,
        },
      };
    case GET_AGENT_DETAIL_FAILED:
      return {
        ...state,
        agentDetail: {
          data: null,
          reqStatus: action.status,
        },
      };
    case GET_AGENT_DETAIL_SUCCESS:
      return {
        ...state,
        agentDetail: {
          data: action.payload,
          reqStatus: action.status,
        },
      };
    case GET_CLIENT_DETAIL:
      return {
        ...state,
        clientDetail: {
          ...state.clientDetail,
          reqStatus: action.status,
        },
      };
    case GET_CLIENT_DETAIL_FAILED:
      return {
        ...state,
        clientDetail: {
          data: null,
          reqStatus: action.status,
        },
      };
    case GET_CLIENT_DETAIL_SUCCESS:
      return {
        ...state,
        clientDetail: {
          data: action.payload,
          reqStatus: action.status,
        },
      };
    case GET_LEADS_CITY:
      return {
        ...state,
        leadsCity: {
          ...state.leadsCity,
          reqStatus: action.status,
        },
      };
    case GET_LEADS_CITY_FAILED:
      return {
        ...state,
        leadsCity: {
          data: [],
          reqStatus: action.status,
        },
      };
    case GET_LEADS_CITY_SUCCESS:
      return {
        ...state,
        leadsCity: {
          data: _.orderBy(
            action.payload?.map((item) => ({
              label: item.name,
              value: item.name,
            })),
            ["label"],
            "asc",
          ),
          reqStatus: action.status,
        },
      };
    case SUBMIT_LEAD_FORM:
      return {
        ...state,
        submitLeadForm: {
          ...state.submitLeadForm,
          reqStatus: action.status,
        },
      };
    case SUBMIT_LEAD_FORM_FAILED:
      return {
        ...state,
        submitLeadForm: {
          data: null,
          reqStatus: action.status,
        },
      };
    case SUBMIT_LEAD_FORM_SUCCESS:
      return {
        ...state,
        submitLeadForm: {
          data: action.payload,
          reqStatus: action.status,
        },
      };
    case SET_INITIAL_STATUS:
      return {
        formDetail: {
          ...state.formDetail,
          reqStatus: "initial",
        },
        leadsCity: {
          ...state.leadsCity,
          reqStatus: "initial",
        },
        submitLeadForm: {
          ...state.submitLeadForm,
          reqStatus: "initial",
        },
      };
    default:
      return state;
  }
}
