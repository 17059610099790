import {
  GET_AGENT_PRIORITY_TNC,
  GET_AGENT_PRIORITY_TNC_SUCCESS,
  GET_AGENT_PRIORITY_TNC_FAILED,
  SET_AGENT_PRIORITY_TNC,
  SET_AGENT_PRIORITY_TNC_SUCCESS,
  SET_AGENT_PRIORITY_TNC_FAILED,
  SET_INITIAL_STATUS,

} from "./ActionTnc";
import _ from "lodash";

const initialState = {
  getAgentPriorityTnc: { reqStatus: "success", data: null, agreementStatus:false },
  setAgentPriorityTnc: { reqStatus: "initial", data: null }
};

export function ReducerTnc(state = initialState, action) {
  switch (action.type) {
    case GET_AGENT_PRIORITY_TNC:
      return {
        ...state,
        getAgentPriorityTnc: {
          ...state.getAgentPriorityTnc,
          reqStatus: action.status,
        },
      };
    case GET_AGENT_PRIORITY_TNC_FAILED:
      return {
        ...state,
        getAgentPriorityTnc: {
          data: null,
          reqStatus: action.status,
        },
      };
    case GET_AGENT_PRIORITY_TNC_SUCCESS:
      return {
        ...state,
        getAgentPriorityTnc: {
          data: action.payload,
          reqStatus: action.status,
          agreementStatus: action.payload.agreementStatus
        },
      };
    case SET_AGENT_PRIORITY_TNC:
      return {
        ...state,
        setAgentPriorityTnc: {
          ...state.setAgentPriorityTnc,
          reqStatus: action.status,
        },
      };
    case SET_AGENT_PRIORITY_TNC_FAILED:
      return {
        ...state,
        setAgentPriorityTnc: {
          data: null,
          reqStatus: action.status,
        },
      };
    case SET_AGENT_PRIORITY_TNC_SUCCESS:
      return {
        ...state,
        setAgentPriorityTnc: {
          data: action.payload,
          reqStatus: action.status,
        },
      };
    case SET_INITIAL_STATUS:
      return {
        getAgentPriorityTnc: {
          ...state.getAgentPriorityTnc,
          reqStatus: "initial",
        },
        setAgentPriorityTnc: {
          ...state.setAgentPriorityTnc,
          reqStatus: "initial",
        }
      };
    default:
      return state;
  }
}
