const ACTION_STATUS = {
  PENDING: "pending",
  SUCCESS: "success",
  FAILED: "failed",
};

export const URL_CHECK = "login/URL_CHECK";
export const URL_CHECK_SUCCESS = "login/URL_CHECK_SUCCESS";
export const URL_CHECK_FAILED = "login/URL_CHECK_FAILED";

export const GET_CONSENT_CONTENT = "login/GET_CONSENT_CONTENT";
export const GET_CONSENT_CONTENT_SUCCESS = "login/GET_CONSENT_CONTENT_SUCCESS";
export const GET_CONSENT_CONTENT_FAILED = "login/GET_CONSENT_CONTENT_FAILED";

export const ACCEPT_CONSENT = "login/ACCEPT_CONSENT";
export const ACCEPT_CONSENT_SUCCESS = "login/ACCEPT_CONSENT_SUCCESS";
export const ACCEPT_CONSENT_FAILED = "login/ACCEPT_CONSENT_FAILED";

export const SET_INITIAL_STATUS = "login/SET_INITIAL_STATUS";

export const urlCheck = (data, callback) => ({
  type: URL_CHECK,
  payload: data,
  status: ACTION_STATUS.PENDING,
  callback,
});
export const urlCheckSuccess = (data) => ({
  type: URL_CHECK_SUCCESS,
  payload: data,
  status: ACTION_STATUS.SUCCESS,
});
export const urlCheckFailed = (data) => ({
  type: URL_CHECK_FAILED,
  payload: data,
  status: ACTION_STATUS.FAILED,
});

export const getConsentContent = (data) => ({
  type: GET_CONSENT_CONTENT,
  payload: data,
  status: ACTION_STATUS.PENDING,
});
export const getConsentContentSuccess = (data) => ({
  type: GET_CONSENT_CONTENT_SUCCESS,
  payload: data,
  status: ACTION_STATUS.SUCCESS,
});
export const getConsentContentFailed = (data) => ({
  type: GET_CONSENT_CONTENT_FAILED,
  payload: data,
  status: ACTION_STATUS.FAILED,
});

export const acceptConsent = (data) => ({
  type: ACCEPT_CONSENT,
  payload: data,
  status: ACTION_STATUS.PENDING,
});
export const acceptConsentSuccess = (data) => ({
  type: ACCEPT_CONSENT_SUCCESS,
  payload: data,
  status: ACTION_STATUS.SUCCESS,
});
export const acceptConsentFailed = (data) => ({
  type: ACCEPT_CONSENT_FAILED,
  payload: data,
  status: ACTION_STATUS.FAILED,
});

export const setInitialStatus = () => ({
  type: SET_INITIAL_STATUS,
});
