import { call, put, takeLatest } from "redux-saga/effects";

import { HTTP_SERVICE } from "../../service/HTTPServiceNew";
import { 
  getAgentPriorityTncSuccess, 
  getAgentPriorityTncFailed, 
  setAgentPriorityTncSuccess, 
  setAgentPriorityTncFailed,
  GET_AGENT_PRIORITY_TNC,
  SET_AGENT_PRIORITY_TNC
} from "./ActionTnc";
import { GET_AGENT_PRIORITY_TNC_API, SET_AGENT_PRIORITY_TNC_API } from "./ConfigTnc";

const { postBase, postWithRoundRobin } = HTTP_SERVICE();

function* workerGetAgentPriorityTnc({ payload }) {
  try {
    const response = yield call(postBase, GET_AGENT_PRIORITY_TNC_API, payload);
    if (response.data.success === true && !Array.isArray(response.data.data)) {
      yield put(getAgentPriorityTncSuccess(response.data.data));
    } else {
      yield put(getAgentPriorityTncFailed(response));
    }
  } catch (error) {
    yield put(getAgentPriorityTncFailed(error));
  }
}

function* workerSetAgentPriorityTnc({ payload, onSuccess, onFailed }) {
  try {
    const response = yield call(postBase, SET_AGENT_PRIORITY_TNC_API, payload);
    if (response.data.success === true ) {
      yield put(setAgentPriorityTncSuccess(response.data.data[0]));
      yield call(onSuccess, response.data.data[0])
    } else {
      yield put(setAgentPriorityTncFailed(response));
      yield call(onFailed, response.data.data[0])
    }
  } catch (error) {
    yield put(setAgentPriorityTncFailed(error));
    yield call(onFailed, error)

  }
}

export const watcherTnc = [
  takeLatest(GET_AGENT_PRIORITY_TNC, workerGetAgentPriorityTnc),
  takeLatest(SET_AGENT_PRIORITY_TNC, workerSetAgentPriorityTnc),
];
