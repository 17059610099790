import { 
    SUMMARY,
    SUMMARY_SUCCESS,
    SUMMARY_FAILED,
    STEP_6,
    STEP_6_SUCCESS,
    STEP_6_FAILED,
    DISCLAIMER,
    DISCLAIMER_SUCCESS,
    DISCLAIMER_FAILED,
    CONFIRMATION_PAGE,
    CONFIRMATION_PAGE_SUCCESS,
    CONFIRMATION_PAGE_FAILED
} from './ConfigNonFace2Face';

//summary

export const summaryFunc = value => ({ type: SUMMARY, value });

export const summarySuccess = value => ({ type: SUMMARY_SUCCESS, value });
export const summaryFailed = value => ({ type: SUMMARY_FAILED, value });

//step 6

export const step6 = value => ({ type: STEP_6, value });

export const step6Success = value => ({ type: STEP_6_SUCCESS, value });
export const step6Failed = value => ({ type: STEP_6_FAILED, value });

//disclaimer

export const disclaimer = value => ({ type: DISCLAIMER, value });

export const disclaimerSuccess = value => ({ type: DISCLAIMER_SUCCESS, value });
export const disclaimerFailed = value => ({ type: DISCLAIMER_FAILED, value });

//confirmation

export const confirmationPage = value => ({ type: CONFIRMATION_PAGE, value });

export const confirmationPageSuccess = value => ({ type: CONFIRMATION_PAGE_SUCCESS, value });
export const confirmationPageFailed = value => ({ type: CONFIRMATION_PAGE_FAILED, value });

//export const closeDialog = value => ({ type: CLOSE_DIALOG, value });

